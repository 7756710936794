import React from "react";
import * as McType from "../@types/manga-checker";
import { Affiliate } from "./affiliate-link";

export const SearchResult: React.FC<{
  result: McType.SearchResult;
}> = ({ result }) => {
  return (
    <>
      <h3>新刊(最大20件)</h3>
      {result.latest.length ? (
        result.latest.map((r) => <Affiliate key={r.eisbn} item={r} />)
      ) : (
        <div>新刊の検索結果はありません。</div>
      )}
      <h3>過去発売分(最大20件)</h3>
      {result.past.length ? (
        result.past.map((r) => <Affiliate key={r.eisbn} item={r} past />)
      ) : (
        <div>既刊検索結果はありません。</div>
      )}
    </>
  );
};
