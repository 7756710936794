import React from "react";
import { TwitterTimelineEmbed } from "react-twitter-embed";
export const Contact: React.FC = () => {
  return (
    <main>
      <TwitterTimelineEmbed
        sourceType="profile"
        screenName="manga_bitto_jp"
        // options={{ height: 400 }}
      />
    </main>
  );
};

export default Contact;
