import React from "react";
import { Button } from "@mui/material";
import { Book } from "../@types/manga-checker";

// アフィリエイトID : yoshidanekoha-22
const AMAZON_ID = "yoshidanekoha-22";
// 検索URL例 : https://www.amazon.co.jp/s?k=9784065270417&tag=yoshidanekoha-22&ref_=nosim

// アフィリエイトリンクは下記URLから有効かどうかを確認できる
// https://affiliate.amazon.co.jp/home/tools/linkchecker?ac-ms-src=ac-nav

// ASINが分かればシンプルに作成できる
// https://www.amazon.co.jp/dp/B09TKVGK64/ref=nosim?tag=yoshidanekoha-22
// https://www.amazon.co.jp/dp/B09TKVGK64/?tag=yoshidanekoha-22

export const AmazonLink: React.FC<{ item: Book }> = (props) => {
  const { item } = props;
  return (
    <>
      <a
        // href={`https://www.amazon.co.jp/s?k=${item.data.summary.isbn}&tag=${AMAZON_ID}&ref_=nosim`}
        href={`https://www.amazon.co.jp/s?k=${item.data.summary.isbn}&rh=n%3A2250738051%2Cn%3A2293143051&dc&rnid=2321267051&tag=${AMAZON_ID}&ref_=nosim`}
        target="_blank"
        rel="noreferrer"
      >
        <Button sx={{backgroundColor:'#ff9900', color:'#ffffff'}} variant='contained'>Amazon</Button>
      </a>
    </>
  );
};
