import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import liff from "@line/liff";
import axios from "axios";
import { Button } from "@mui/material";
function Logout() {
  const nav = useNavigate();
  useEffect(() => {
    axios.post("/api/v1/logout");
    if (liff) {
      liff.logout();
      nav("/");
    }
  }, [nav]);
  return (
    <>
      ログアウトしました。
      <Button onClick={window.close}>閉じる</Button>
    </>
  );
}

export default Logout;
