import { FC, createContext, useEffect, useState } from "react";
import axios from "axios";
import { Affiliate } from "../components/affiliate-link";
import { Book } from "../@types/manga-checker";
import { DateLabel } from "../components/date-label";
const KEY = "LATEST_BOOKS";
type LatestBooks = { lastModified: number; latestBooks: Book[] };

// カレントのブックリスト
export const BooksContext = createContext<Book[] | null>(null);

function LatestList() {
  const [latestList, setLatestList] = useState<Book[] | null>(null);

  // 最新情報の取得
  useEffect(() => {
    if (latestList) return;
    const cache = JSON.parse(
      localStorage.getItem(KEY) || `{"lastModified":-1}`
    ) as LatestBooks;
    axios
      .get<LatestBooks>(`/api/v1/latest-list`, {
        headers: { "if-modified-since": `${cache.lastModified}` },
      })
      .then((res) => {
        if (res == null) {
          setLatestList(cache.latestBooks);
        } else {
          setLatestList(res.data?.latestBooks);
          localStorage.setItem(KEY, JSON.stringify(res.data));
        }
      });
  }, [latestList]);

  return (
    <div className="App">
      <header className="App-header">
        <h1>新刊リスト</h1>
        {latestList ? (
          <p>
            <DateLabel date={latestList[0].pub_date} />は
            {latestList?.length || 0}冊が発売されます。
          </p>
        ) : (
          <p>新刊情報がありません。</p>
        )}
        {latestList?.map((m) => (
          <Affiliate key={m.eisbn} item={m} />
        ))}
      </header>
    </div>
  );
}

export default LatestList;
