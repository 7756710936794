import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import liff from "@line/liff";
import { Alert, TextField, Button, Snackbar, FormControl } from "@mui/material";
import { MyKeyword, SearchResult } from "../@types/manga-checker";
import { Search } from "@mui/icons-material";
import { SearchResult as SearchResultFC } from "../components/search-result";
import { Recommend } from "../components/recommend";
import { trim, han2zen } from "../dic-words";

const BLANK_VALUES = {
  title: "",
  written_by: "",
};

const toSearchWord = (s: string = ""): string => {
  return han2zen(trim(s.toUpperCase()));
};

function KeywordSearch() {
  const navigate = useNavigate();
  const [values, setValues] = useState<MyKeyword>({ ...BLANK_VALUES });
  const [result, setResult] = useState<SearchResult | null>(null);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    severity: "warning" | "info" | undefined;
    msg: string | undefined;
  }>({ open: false, severity: undefined, msg: "" });

  // 検索ワード変更時にモデル更新
  function textChange(e: React.ChangeEvent<HTMLInputElement>) {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
    setResult(null);
  }

  const openSnackbar = (
    msg: string,
    severity?: "warning" | "info" | undefined
  ) => {
    setSnackbar({ open: true, severity: severity || "warning", msg });
  };
  const closeSnackbar = () => {
    setSnackbar({ open: false, severity: undefined, msg: "" });
  };

  // キーワード検索
  const keywordSearch = async () => {
    const dicWords = {
      ...values,
      title: toSearchWord(values.title),
      written_by: toSearchWord(values.written_by),
    };
    setValues({ ...dicWords });
    const res = await axios.get<SearchResult>("/api/v1/keyword-search", {
      params: dicWords,
    });
    res && setResult(res.data);
  };

  // キーワードクリア
  const clearKeywords = () => {
    setValues({ ...BLANK_VALUES });
    setResult(null);
  };

  // キーワード登録
  const postKeyword = async () => {
    await axios
      .post("/api/v1/preferences", values)
      .then((res) => {
        if (res) {
          openSnackbar(
            `「${values.title || "-"}/${
              values.written_by || "-"
            }」を登録しました`,
            "info"
          );
          clearKeywords();
        }
      })
      .catch((e) => openSnackbar("登録に失敗しました"));
  };

  // おすすめキーワード検索
  const recomendItem = (item: MyKeyword) => {
    setValues({ ...item });
    // setTimeout(keywordSearch,100);
  };

  return (
    <>
      <header>
        <h1>キーワードで検索！</h1>
      </header>
      <>
        <FormControl>
          <TextField
            name="title"
            value={values.title}
            label="タイトル検索ワード"
            onChange={textChange}
          />
          <TextField
            name="written_by"
            value={values.written_by}
            label=" 著者・作者検索ワード"
            onChange={textChange}
          />
          <div>
            <Button variant="contained" onClick={keywordSearch}>
              検索
              <Search />
            </Button>
            <Button onClick={clearKeywords}>クリア</Button>
          </div>
        </FormControl>
        {!result && (
          <p>
            💡「タイトル」「著者・作者」のどちらか、または両方にキーワードを入力してください。
          </p>
        )}
      </>
      {!values.title && !values.written_by && <Recommend cb={recomendItem} />}

      {result && (
        <>
          {liff.isLoggedIn() && (
            <Button variant="contained" onClick={postKeyword}>
              この条件を登録
            </Button>
          )}

          <SearchResultFC result={result} />
          {liff.isLoggedIn() &&
            result.latest?.length + result.past?.length > 5 && (
              <>
                <Button variant="contained" onClick={postKeyword}>
                  この条件を登録
                </Button>
              </>
            )}
          {!liff.isLoggedIn() && (
            <Button
              variant="contained"
              onClick={() => navigate("/liff/keyword-search")}
            >
              LINEログインしてもっと便利に！
            </Button>
          )}
          {liff.isLoggedIn()}
        </>
      )}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={closeSnackbar}
        onClick={closeSnackbar}
      >
        <Alert severity={snackbar.severity}>{snackbar.msg}</Alert>
      </Snackbar>
    </>
  );
}

export default KeywordSearch;
