import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { Book } from "../@types/manga-checker";
import * as Encoding from "encoding-japanese";

// アフィリエイトID :
const RAKUTEN_ID = "19f40114.a1bb39ea.19f40115.b21e613b";
// 本のURL : https://books.rakuten.co.jp/rb/17040119/

export const RakutenLink: React.FC<{ item: Book }> = (props) => {
  const { item } = props;
  const [rakutenLink, setRakutenLink] = useState<string>("");

  useEffect(() => {
    const unicodeArray = Encoding.stringToCode(item.data.summary.isbn || "");
    const encoded = Encoding.convert(unicodeArray, {
      to: "EUCJP",
      from: "UNICODE",
    });
    setRakutenLink(Encoding.urlEncode(encoded));
  }, [item]);

  return (
    <>
      <a
        // href={`https://hb.afl.rakuten.co.jp/hgc/${RAKUTEN_ID}/?pc=https://books.rakuten.co.jp/rb/${rakutenLink}/`}
        href={`http://hb.afl.rakuten.co.jp/hgc/${RAKUTEN_ID}/?pc=http://search.books.rakuten.co.jp/bksearch/nm?sitem=${rakutenLink}&m=http://m.rakuten.co.jp/ev/book/</>`}
        target="_blank"
        rel="noreferrer"
      >
        <Button sx={{backgroundColor:'#e61717',color:'#ffffff'}} variant='contained'>楽天</Button>
      </a>
    </>
  );
};