export const ContributorRole: { [key: string]: string } = {
  A01: "著・文・その他",
  A03: "脚本",
  A06: "作曲",
  B01: "編集",
  B20: "監修",
  B06: "翻訳",
  A12: "イラスト",
  A38: "原著",
  A10: "企画・原案",
  A08: "写真",
  A21: "解説",
  E07: "朗読",
};

export const getContributorRoles = (roles: string[]): string => {
  return roles.map((role) => ContributorRole[role] || "?").join(",");
};
