const replaceMap: { [key: string]: string } = {
  ｶﾞ: "ガ",
  ｷﾞ: "ギ",
  ｸﾞ: "グ",
  ｹﾞ: "ゲ",
  ｺﾞ: "ゴ",
  ｻﾞ: "ザ",
  ｼﾞ: "ジ",
  ｽﾞ: "ズ",
  ｾﾞ: "ゼ",
  ｿﾞ: "ゾ",
  ﾀﾞ: "ダ",
  ﾁﾞ: "ヂ",
  ﾂﾞ: "ヅ",
  ﾃﾞ: "デ",
  ﾄﾞ: "ド",
  ﾊﾞ: "バ",
  ﾋﾞ: "ビ",
  ﾌﾞ: "ブ",
  ﾍﾞ: "ベ",
  ﾎﾞ: "ボ",
  ﾊﾟ: "パ",
  ﾋﾟ: "ピ",
  ﾌﾟ: "プ",
  ﾍﾟ: "ペ",
  ﾎﾟ: "ポ",
  ｳﾞ: "ヴ",
  ﾜﾞ: "ヷ",
  ｦﾞ: "ヺ",
  ｱ: "ア",
  ｲ: "イ",
  ｳ: "ウ",
  ｴ: "エ",
  ｵ: "オ",
  ｶ: "カ",
  ｷ: "キ",
  ｸ: "ク",
  ｹ: "ケ",
  ｺ: "コ",
  ｻ: "サ",
  ｼ: "シ",
  ｽ: "ス",
  ｾ: "セ",
  ｿ: "ソ",
  ﾀ: "タ",
  ﾁ: "チ",
  ﾂ: "ツ",
  ﾃ: "テ",
  ﾄ: "ト",
  ﾅ: "ナ",
  ﾆ: "ニ",
  ﾇ: "ヌ",
  ﾈ: "ネ",
  ﾉ: "ノ",
  ﾊ: "ハ",
  ﾋ: "ヒ",
  ﾌ: "フ",
  ﾍ: "ヘ",
  ﾎ: "ホ",
  ﾏ: "マ",
  ﾐ: "ミ",
  ﾑ: "ム",
  ﾒ: "メ",
  ﾓ: "モ",
  ﾔ: "ヤ",
  ﾕ: "ユ",
  ﾖ: "ヨ",
  ﾗ: "ラ",
  ﾘ: "リ",
  ﾙ: "ル",
  ﾚ: "レ",
  ﾛ: "ロ",
  ﾜ: "ワ",
  ｦ: "ヲ",
  ﾝ: "ン",
  ｧ: "ァ",
  ｨ: "ィ",
  ｩ: "ゥ",
  ｪ: "ェ",
  ｫ: "ォ",
  ｯ: "ッ",
  ｬ: "ャ",
  ｭ: "ュ",
  ｮ: "ョ",
  "｡": "。",
  "､": "、",
  ｰ: "ー",
  "｢": "「",
  "｣": "」",
  "･": "・",
};

// 半角記号と全角記号の列挙
// const KIGOU_HAN = "!@#$%^&*()[{}]-=,./+\\`~;:'\"<>".split("");
// const KIGOU_ZEN =
//   "！＠＃＄％＾＆＊（）［｛｝］ー＝，．／＋＼｀〜；：’”＜＞".split("");
// KIGOU_HAN.forEach((c, i) => {
//   replaceMap[c] = KIGOU_ZEN[i]!;
// });

const reg = new RegExp("(" + Object.keys(replaceMap).join("|") + ")", "g");
// マップを利用して半角を全角に
function map2zen(str: string): string {
  return str
    .replace(reg, (match) => replaceMap[match] || match)

    .replace(/ﾞ/g, "゛")
    .replace(/ﾟ/g, "゜");
}

// 半角をひたすら全角にする関数
export function han2zen(org: string): string {
  return map2zen(ascii2zen(org));
}

export function blankRemover(str: string): string {
  return str.replace(/[\s\u3000]/g, "");
}

//
export const trim = (s: string = ""): string => {
  return s.replace(/^[\s\u3000]+|[\s\u3000]+$/g, "");
};

// 英数字を全角に
export function ascii2zen(str: string): string {
  return str.replace(/[A-Za-z0-9]/g, function (s) {
    return String.fromCharCode(s.charCodeAt(0) + 0xfee0);
  });
}

// 検索ワードに変換
export const toDicWord = (s: string = ""): string => {
  return han2zen(blankRemover(s.toUpperCase()));
};

const DicWords = {
  blankRemover,
  trim,
  ascii2zen,
  han2zen,
  toDicWord,
};

export default DicWords;
