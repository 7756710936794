import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  MenuItem,
  Select,
  SelectChangeEvent,
  List,
  ListItem,
} from "@mui/material";

import { PreferencesData, MyKeyword } from "../../@types/manga-checker";

/**
 * 設定画面
 */

const NOTIFY_DATES = "日月火水木金土".split(""); // 週

function Preferences() {
  const navi = useNavigate();
  // 初期データ
  const [preferencesData, setPreferencesData] = useState<PreferencesData>({
    notificationDate: -1,
    keywords: [],
  });

  function getPreferences() {
    axios.get<PreferencesData>("/api/v1/preferences").then((res) => {
      const { data } = res;
      setPreferencesData(data);
    });
  }

  // 初回のみ
  useEffect(getPreferences, []);

  // 設定クリックで編集画面へ
  const openEditor = (keyword: MyKeyword) => {
    const editorUrl = `/liff/preferences/edit/${keyword.id}?title=${keyword.title}&written_by=${keyword.written_by}`;
    navi(editorUrl);
  };

  // 通知日変更(0は無効、通知しない)
  function changeNotificationDate(e: SelectChangeEvent<string>) {
    const value = e.target.value;
    setPreferencesData({
      ...preferencesData,
      notificationDate: +value,
    });
    // update notification date
    axios.put("/api/v1/preferences/notify-date", { notify_date: value });
  }

  return (
    <div>
      <header>
        <h1>通知設定</h1>
        <p>
          毎週の通知曜日を選択してください。
          <br />
          通知は昼12時頃です。
        </p>
      </header>
      <main>
        <Select
          value={`${preferencesData.notificationDate}`}
          onChange={changeNotificationDate}
        >
          <MenuItem value={-1}>通知オフ</MenuItem>
          {NOTIFY_DATES.map((d, i) => (
            <MenuItem value={i} key={i}>
              {d}曜日
            </MenuItem>
          ))}
        </Select>
      </main>

      <header>
        <h1>検索設定</h1>
        <p>検索ワードを変更・削除できます。</p>
      </header>
      <main>
        <List>
          {preferencesData?.keywords.map((keyword) => {
            return (
              <ListItem
                key={keyword.id}
                sx={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  paddingLeft: 0,
                  listStyleType: "decimal",
                }}
              >
                <div onClick={() => openEditor(keyword)}>
                  {keyword.title || "-"} / {keyword.written_by || "-"}
                </div>
              </ListItem>
            );
          })}
        </List>
      </main>
    </div>
  );
}

export default Preferences;
