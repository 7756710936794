import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const { REACT_APP_GA_ID } = process.env;

export const GA4: React.FC<{}> = () => {
  const location = useLocation();

  useEffect(() => {
    if (!REACT_APP_GA_ID) return;

    const gaOptions: Gtag.EventParams = { screen_name: location.pathname };
    window.gtag("event", "screen_view", gaOptions);
  }, [location]);

  return <></>;
};
