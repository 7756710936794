import React from "react";
import { Button } from "@mui/material";
export function Top() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>マンガ新刊チェックへようこそ！</h1>
        <div>
          マンガの新刊、いつの間にか出ていた・・・そんな経験ありませんか？
          <br />
          「マンガ新刊チェック！」では、あなたのかわりに新刊チェックします！
        </div>
      </header>

      <main>
        <h2>LINEでログインして使う</h2>
        <a href={`/liff/keyword-search`}>LINEでログイン</a>
        すると、検索条件の保存や自動通知などの機能が使えます！
        <div className="center">
          <a href={`/liff/keyword-search`}>
            <img
              className="qr-code"
              src="/images/manga-check-qr.png"
              alt="QRコード"
            />
            <br />
            <Button variant="contained">こちらから</Button>
          </a>
        </div>
      </main>
      <main>
        <h2>ログインせずに使う</h2>
        ログインなしで、とりあえず使ってみたい方はこちらから。
        <br />
        <div style={{ textAlign: "center" }}>
          <a href="/keyword-search">
            <Button variant="contained">ログインせずに使う</Button>
          </a>
        </div>
      </main>
    </div>
  );
}

export default Top;
