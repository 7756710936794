import React, { useState } from "react";
import { Typography, Card, CardContent, CardActions } from "@mui/material";
import { Book } from "../@types/manga-checker";
import { RakutenLink } from "./rakuten-link";
import { AmazonLink } from "./amazon-link";
import { BookDetail } from "./book-detail";
import { DateLabel } from "./date-label";

// タイトルに巻数を表示する
export const getTitle = (item: Book): string => {
  const title = item.data.summary.title;
  const partNumber =
    item.data.onix.DescriptiveDetail.TitleDetail.TitleElement.PartNumber;

  if (!partNumber) {
    return title;
  }

  const zenNum = partNumber.replace(/[\d]/g, (n) => "０１２３４５６７８９"[+n]);

  if (~title.indexOf(partNumber) || ~title.indexOf(zenNum)) {
    return title;
  }
  return `${title} 【${partNumber}】`;
};

export const Affiliate: React.FC<{ item: Book; past?: boolean }> = (props) => {
  const { item } = props;
  const [open, setOpen] = useState(false);

  return (
    <Card>
      <BookDetail item={item} open={open} setOpen={setOpen} />
      <CardContent onClick={() => setOpen(true)}>
        {item.data.summary.cover && (
          <img src={`${item.data.summary.cover}`} alt="表紙" />
        )}
        <Typography sx={{ fontSize: 14 }} color="text.primary" gutterBottom>
          <DateLabel date={item.pub_date} />
        </Typography>
        <Typography sx={{ fontSize: 18 }} color="text.primary" gutterBottom>
          {getTitle(item)}
        </Typography>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {item.data.summary.author}
          <br />
          {item.company}
        </Typography>
      </CardContent>
      <CardActions>
        <RakutenLink item={item} />
        <AmazonLink item={item} />
      </CardActions>
    </Card>
  );
};
