import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import axios from "axios";
import { Affiliate } from "../components/affiliate-link";
import { Book } from "../@types/manga-checker";

type MyListJson = {
  newList: Book[];
  pastList: Book[];
};

// 最新情報の取得
function MyList() {
  const nav = useNavigate();
  const [myList, setMyList] = useState<MyListJson | null>(null);

  useEffect(() => {
    !myList &&
      axios.get<MyListJson>("/api/v1/my-list").then((res) => {
        // 新刊

        // 既刊
        setMyList(res.data);
      });
  }, [myList]);

  return (
    <div className="App">
      <header className="App-header">
        <h1>マイリスト{myList ? `(新刊${myList?.newList.length}件)` : ""}</h1>
      </header>

      {myList?.newList.length ? (
        <>
          <p>チェック対象の新刊リストです！</p>
          {myList?.newList.map((m) => (
            <Affiliate key={m.eisbn} item={m} />
          ))}
        </>
      ) : (
        <>
          <p>
            検索ワード未登録、または検索結果がありません。
            <br />
            検索キーワードを登録しましょう！
          </p>
          <Button
            onClick={() => nav("/liff/keyword-search")}
            variant="contained"
          >
            まずは検索！！
          </Button>
        </>
      )}
      {myList?.pastList.length ? (
        <>
          <hr />
          <h2>買い逃しはありませんか？</h2>
          <div>過去1週間以内に発売された新刊リストです。</div>
          {myList?.pastList.map((m) => (
            <Affiliate key={m.eisbn} item={m} />
          ))}
        </>
      ):<></>}
    </div>
  );
}

export default MyList;
