import React from "react";
import { Menu, MenuItem, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import liff from "@line/liff";
import { Menu as MenuIcon } from "@mui/icons-material";

type Nav = {
  title: string;
  href: string;
  type: "liff" | "nologin" | "static";
};

const navs: Nav[] = [
  // no login
  { title: "まずは検索！", href: "/keyword-search", type: "nologin" },
  { title: "LINEでログイン", href: "/liff/keyword-search", type: "nologin" },

  // liff
  { title: "まずは検索！", href: "/liff/keyword-search", type: "liff" },
  { title: "マイリスト", href: "/liff/my-list", type: "liff" },
  { title: "通知・検索設定", href: "/liff/preferences", type: "liff" },
  { title: "お問い合わせ(Twitter)", href: "/liff/contact", type: "liff" },

  // static
  { title: "プライバシーポリシー", href: "/privacy-policy", type: "static" },

  //   { title: "ログアウト", href: "/logout", type: "liff" },
];

export const AppMenu: React.FC<{}> = () => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const move = (nav: Nav) => {
    navigate(nav.href);
    handleClose();
  };

  return (
    <>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MenuIcon />
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {navs.map((nav) => {
          const view =
            (nav.type === "liff" && liff?.isLoggedIn()) ||
            (nav.type === "nologin" && !liff?.isLoggedIn()) ||
            nav.type === "static";

          return (
            view && (
              <MenuItem key={nav.title} onClick={() => move(nav)}>
                {nav.title}
              </MenuItem>
            )
          );
        })}
      </Menu>
    </>
  );
};

export default AppMenu;
