import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { AppHeaderBar, AppFooterBar, Offset } from "./App";
import "./settings/axios-settings";
import { theme } from "./settings/mui-theme";

// liff
import Preferences from "./liff/preferences/";
import PreferencesEdit from "./liff/preferences/edit";
import MyList from "./liff/my-list";
import KeywordSearch from "./liff/keyword-search";
import Contact from "./liff/contact";
import Logout from "./liff/logout";
import LatestList from "./liff/latest-list";

// ログイン無しで見られる場所
import { Top } from "./top";
import { PrivacyPolicy } from "./privacy-policy/";

import reportWebVitals from "./reportWebVitals";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { LiffBlock } from "./liff/liff-block";

import { Box, ThemeProvider } from "@mui/material";
import { GlobalAlert } from "./components/global-alert";
import { McBackdrop } from "./components/backdrop";
import { GA4 } from "./components/ga4";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Box>
        <GlobalAlert />
        <McBackdrop />
        <BrowserRouter>
          <GA4 />
          <LiffBlock>
            <AppHeaderBar />
            <Routes>
              <Route path="*" element={<Top />} />
              <Route path="/keyword-search" element={<KeywordSearch />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route
                path="/liff/privacy-policy/*"
                element={<PrivacyPolicy />}
              />
              <Route path="/liff/keyword-search" element={<KeywordSearch />} />
              <Route path="/liff/latest-list" element={<LatestList />} />
              <Route path="/liff/my-list" element={<MyList />} />
              <Route path="/liff/preferences" element={<Preferences />} />
              <Route
                path="/liff/preferences/edit/:id"
                element={<PreferencesEdit />}
              />
              <Route path="/liff/contact" element={<Contact />} />
              <Route path="/logout" element={<Logout />} />
            </Routes>
            <AppFooterBar />
          </LiffBlock>
        </BrowserRouter>
        <Offset />
      </Box>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
