import React from "react";
import {
  FormControl,
  Select,
  MenuItem,
  ListItemText,
  SelectChangeEvent,
} from "@mui/material";
import { MyKeyword } from "../../@types/manga-checker";

import { RECOMMENDS } from "./recommend-list";

// ランダムに5件抽出する
const recommend5 = [...RECOMMENDS.sort(() => Math.random() - 0.5).slice(0, 5)];

export const Recommend: React.FC<{ cb: (item: MyKeyword) => void }> = ({
  cb,
}) => {
  function changeReccomend(e: SelectChangeEvent<string>) {
    cb(recommend5[+e.target.value]);
  }

  return (
    <FormControl>
      <Select onChange={changeReccomend} value={`-1`}>
        <MenuItem value="-1">
          <ListItemText primary="おすすめ検索" />
        </MenuItem>
        {recommend5.map((item, i) => (
          <MenuItem key={i} value={`${i}`}>
            <ListItemText
              sx={{ whiteSpace: "initial" }}
              primary={`${item.title} ${
                item.title && item.written_by ? " / " : ""
              } ${item.written_by}`}
              // secondary={item.catchCopy}
            />
          </MenuItem>
        ))}
      </Select>
      <div>選んだら「検索」ボタンを押してください。</div>
    </FormControl>
  );
};
