import React from "react";
import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Book } from "../@types/manga-checker";
import { RakutenLink } from "./rakuten-link";
import { AmazonLink } from "./amazon-link";
import { getContributorRoles } from "../@types/openbd-codes";
import { DateLabel } from "./date-label";
import { getTitle } from "./affiliate-link";

// 本の明細ダイアログ
export const BookDetail: React.FC<{
  item: Book;
  open: boolean;
  setOpen: (open: boolean) => void;
}> = ({ item, open, setOpen }) => {
  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xl">
        <DialogTitle>{getTitle(item)}</DialogTitle>
        <DialogContent>
          {item.data.summary.cover && (
            <img src={`${item.data.summary.cover}`} alt="表紙" />
          )}
          <Typography sx={{ fontSize: 12 }} color="GrayText.primary">
            <DateLabel date={item.pub_date} /> 発売
            {item.data.onix.ProductSupply.SupplyDetail.Price[0]?.PriceAmount &&
              ` ${item.data.onix.ProductSupply.SupplyDetail.Price[0]?.PriceAmount}円`}
            <br />
            {item.data.summary.author}
            <br />
            {item.data.summary.publisher}
          </Typography>
          <div></div>
          {item.data.onix.CollateralDetail?.TextContent?.length && (
            <>
              {item.data.onix.CollateralDetail.TextContent.map(
                (content, idx) => (
                  <Typography
                    key={idx}
                    sx={{ fontSize: 12, marginTop: "1em" }}
                    color="GrayText.primary"
                  >
                    {content.Text}
                  </Typography>
                )
              )}
            </>
          )}
          <div></div>
          {item.data.onix.DescriptiveDetail.Contributor.map(
            (contributor, idx) =>
              contributor.BiographicalNote && (
                <div key={idx}>
                  <Typography
                    sx={{
                      fontSize: 12,
                      marginTop: "1em",
                    }}
                    color="text.primary"
                  >
                    {contributor.PersonName.content}(
                    {getContributorRoles(contributor.ContributorRole)})
                  </Typography>
                  <Typography sx={{ fontSize: 12 }} color="text.secondaary">
                    {contributor.BiographicalNote}
                  </Typography>
                </div>
              )
          )}
          <hr />
          <Typography
            sx={{
              fontSize: 12,
              marginTop: "1em",
            }}
            color="text.primary"
          >
            {item.data.hanmoto.datecreated &&
              `データ登録: ${new Date(
                item.data.hanmoto.datecreated.replace(/-/g, "/")
              ).toLocaleString()} `}
            <br />
            {item.data.hanmoto.datemodified &&
              `データ更新: ${new Date(
                item.data.hanmoto.datemodified.replace(/-/g, "/")
              ).toLocaleString()}`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <RakutenLink item={item} />
          <AmazonLink item={item} />
          <Button onClick={() => setOpen(false)} sx={{ marginLeft: "8px" }}>
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
