import { useState, useEffect, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Snackbar } from "@mui/material";
import liff from "@line/liff";

export function LiffBlock(props: { children: ReactNode }) {
  const [isliffDefined, setliffDefined] = useState(false);
  const [liffError, setLiffError] = useState<string | null>(null);
  const navigate = useNavigate();

  // Execute liff.init() when the app is initialized
  useEffect(() => {
    liff
      .init({ liffId: `${process.env.REACT_APP_LIFF_ID}` })
      .then(() => {
        // liffオブジェクト初期化済み(childrenでisLoggedInが使える)
        setliffDefined(true);

        // ログイン済み
        if (liff.isLoggedIn()) {
          // 表示したかったページの復元
          const urlCache = localStorage.getItem("URL_CACHE");
          if (urlCache) {
            navigate(urlCache);
            localStorage.removeItem("URL_CACHE");
          }
        } else if (/^\/liff/.test(global.location.pathname)) {
          // 未認証、liff認証が必要なページならログインさせる
          localStorage.setItem("URL_CACHE", global.location.pathname);
          liff.login();
        }
        // 通信401エラーはaxiosに任せる
      })
      .catch((err) => {
        console.error(err);
        setLiffError(err.toString());
      });
  }, [navigate]);

  return (
    <>
      {isliffDefined && props.children}
      <Snackbar
        open={!!liffError}
        autoHideDuration={4000}
        onClose={() => setLiffError(null)}
        onClick={() => setLiffError(null)}
      >
        <Alert severity="error">{liffError}</Alert>
      </Snackbar>
    </>
  );
}

export default LiffBlock;
