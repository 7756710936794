import axios from "axios";
import liff from "@line/liff";
const cbobj: { ecb: Function | null } = { ecb: null };

let reqCnt = 0;

// リクエスト時の設定
axios.interceptors.request.use((config) => {
  config.baseURL = process.env.REACT_APP_FASTIFY_REMOTE_HOST;
  return config;
});

axios.interceptors.request.use((config) => {
  config.headers = config.headers || {};

  // トークンがあればセットする
  const token = liff.getAccessToken();
  if (token) {
    config.headers.Authorization = `Bearer ${liff.getAccessToken()}`;
  }
  reqCnt++;
  requestHandlers.forEach((f) => f());
  return config;
});

// エラーになったときに呼ぶ関数をセット
export const setErrorHandler = (cb: Function) => {
  cbobj.ecb = cb;
};

type McRhandler = () => void;
// リクエストハンドラの追加
const requestHandlers: Array<McRhandler> = [];
export const addRequestHandler = (handler: McRhandler) => {
  ~requestHandlers.indexOf(handler) || requestHandlers.push(handler);
};

// レスポンスハンドラの追加
const responseHandlers: Array<McRhandler> = [];
export const addResponseHandler = (handler: McRhandler) => {
  ~responseHandlers.indexOf(handler) || responseHandlers.push(handler);
};

axios.interceptors.response.use(
  (value) => {
    reqCnt--;
    reqCnt === 0 && responseHandlers.forEach((f) => f());
    return value;
  },
  (e) => {
    reqCnt--;
    reqCnt === 0 && responseHandlers.forEach((f) => f());
    const status = e.response?.status;
    if (status === 401) {
      // 表示したかったページを覚えておく
      localStorage.setItem("URL_CACHE", global.location.pathname);
      liff.login();
      return;
    } else if (status === 304) {
      // console.log(`${e.config} 304`);
      return;
    }

    const msg =
      e.response?.data?.message || "想定外のエラーが発生しました(E003)";
    console.error(msg);
    cbobj.ecb && cbobj.ecb(msg);
  }
);
