import React, { useEffect, useState, useMemo } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { TextField, Button, FormControl } from "@mui/material";
import { Edit, Search, Delete } from "@mui/icons-material";
import { SearchResult, MyKeyword } from "../../@types/manga-checker";
import { SearchResult as SearchResultFC } from "../../components/search-result";
import { trim, han2zen } from "../../dic-words";
export type PreferencesEditProps = {
  open: boolean;
  item: MyKeyword | undefined;
};

const toSearchWord = (s: string = ""): string => {
  return han2zen(trim(s.toUpperCase()));
};

export const PreferencesEdit = () => {
  const [result, setResult] = useState<SearchResult | null>(null);
  const [values, setValues] = useState<MyKeyword>({
    title: "",
    written_by: "",
  });

  const navi = useNavigate();
  const search = useLocation().search;
  const searchParams = useMemo(() => {
    const params = new window.URLSearchParams(search);
    return [params.get("title") || "", params.get("written_by") || ""];
  }, [search]);
  const pathParams = useParams();

  // 初期表示
  useEffect(() => {
    const id = pathParams.id;
    const [title, written_by] = searchParams;

    if (id) {
      const values = { id, title, written_by };
      setValues({ ...values });
      callAPI(values);
    }
  }, [pathParams.id, searchParams]);

  // 編集画面を閉じて設定一覧に戻る
  const closePage = () => {
    navi("/liff/preferences");
  };

  // 検索ワード変更時にモデル更新
  function textChange(e: React.ChangeEvent<HTMLInputElement>) {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
    setResult(null);
  }

  async function updateItem() {
    await axios
      .put("/api/v1/preferences", values)
      .then((res) => res && closePage());
  }

  async function deleteItem() {
    await axios
      .delete(`/api/v1/preferences/${values.id}`)
      .then((res) => res && closePage());
  }

  // キーワード検索ボタン
  async function searchItem() {
    const dicWords = {
      ...values,
      title: toSearchWord(values.title),
      written_by: toSearchWord(values.written_by),
    };
    setValues(dicWords);
    callAPI(dicWords);
  }

  // キーワードで検索する
  async function callAPI(values: MyKeyword) {
    const res = await axios.get<SearchResult>("/api/v1/keyword-search", {
      params: values,
    });
    setResult(res.data);
  }

  const EditActions: React.FC = () => {
    return (
      <>
        <Button variant="contained" onClick={updateItem}>
          更新
          <Edit />
        </Button>
        <Button color="error" variant="contained" onClick={deleteItem}>
          削除
          <Delete />
        </Button>
        <Button onClick={closePage}>戻る</Button>
      </>
    );
  };

  return (
    <>
      <header>
        <h1>検索ワード編集</h1>
      </header>
      <main>
        <FormControl>
          <TextField
            name="title"
            value={values.title}
            label="タイトル検索ワード"
            onChange={textChange}
          />
          <TextField
            name="written_by"
            value={values.written_by}
            label=" 著者・作者検索ワード"
            onChange={textChange}
          />
          <div>
            <Button variant="contained" onClick={searchItem}>
              検索結果を確認
              <Search />
            </Button>
          </div>
        </FormControl>
        {result && (
          <>
            <EditActions />
            <SearchResultFC result={result} />
          </>
        )}
      </main>
      <footer>
        <EditActions />
      </footer>
    </>
  );
};

export default PreferencesEdit;
