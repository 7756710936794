import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import {
  addRequestHandler,
  addResponseHandler,
} from "../settings/axios-settings";

// くるくる画面
export const McBackdrop: React.FC = () => {
  const [open, setOpen] = useState(false);

  const reqHandler = () => setOpen(true);
  const resHandler = () => setOpen(false);

  useEffect(() => {
    addRequestHandler(reqHandler);
    addResponseHandler(resHandler);
  }, []);

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default McBackdrop;
