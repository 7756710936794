import { createTheme } from "@mui/material";

export const DEFAULT_SPACE = "0.8em";

export const theme = createTheme({
  components: {
    MuiFormControl: {
      defaultProps: {
        fullWidth: true,
        sx: { boxSizing: "border-box" },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
        sx: { mb: DEFAULT_SPACE },
      },
    },
    MuiTable: {
      defaultProps: {
        size: "small",
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "outlined",
        sx: { marginRight: DEFAULT_SPACE, marginBottom: DEFAULT_SPACE },
      },
    },
    MuiIconButton: {
      defaultProps: {},
    },
    MuiBackdrop: {
      defaultProps: {
        sx: { zIndex: 2000 },
      },
    },
    MuiCard: {
      defaultProps: {
        variant: "outlined",
        sx: {
          marginBottom: DEFAULT_SPACE,
        },
      },
    },
    MuiChip: {
      defaultProps: {
        variant: "outlined",
        color: "primary",
      },
    },
    MuiCardContent: {
      defaultProps: {
        sx: {
          padding: DEFAULT_SPACE,
        },
      },
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
});
