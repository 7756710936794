import React from "react";

const DAYS='日月火水木金土'

// 日付表記フォーマット
const formatDate = (date?: string): string => {
  if (!date) return ``;

  const str = date.padEnd(8, "9");

  const year = str.substring(0, 4);
  const month = +str.substring(4, 6);
  const day = +str.substring(6, 8);

  const dayOfWeek=new Date(`${year}/${month}/${day}`).getDay()
  const weekDay=dayOfWeek ? `(${DAYS[dayOfWeek]})` : ``

  return `${year}年${month}月${day}日${weekDay}`;
};

export const DateLabel: React.FC<{ date?: string }> = ({ date }) => {
  return <>{formatDate(date)}</>;
};
