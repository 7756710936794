import { MyKeyword } from "../../@types/manga-checker";

export const RECOMMENDS: MyKeyword[] = [
  { title: "葬送のフリーレン", written_by: "山田鐘人" },
  { title: "ふらいんぐうぃっち", written_by: "石塚千尋" },
  { title: "かぐや様は告らせたい", written_by: "赤坂アカ" },
  { title: "推しの子", written_by: "赤坂アカ" },
  { title: "ウィッチクラフトワークス", written_by: "水薙竜" },
  { title: "呪術廻戦", written_by: "芥見下々" },
  { title: "幼女戦記", written_by: "東條チカ" },
  { title: "空挺ドラゴンズ", written_by: "桑原太矩" },
  { title: "ぐらんぶる", written_by: "井上堅二" },
  { title: "ゲーミングお嬢様", written_by: "" },
  { title: "不滅のあなたへ", written_by: "大今良時" },
  { title: "ヴィンランド サガ", written_by: "幸村誠" },
  { title: "ＳＰＹ ＦＡＭＩＬＹ", written_by: "遠藤達哉" },
  { title: "烈海王は異世界転生しても一向にかまわんッッ", written_by: "" },
  { title: "ドリフターズ", written_by: "平野耕太" },
  { title: "夜桜四重奏", written_by: "ヤスダスズヒト" },
  { title: "メイドインアビス", written_by: "つくしあきひと" },
  {
    title: "何度 時をくりかえしても 本能寺が燃えるんじゃが",
    written_by: "藤本ケンシ",
  },
  { title: "妻 小学生になる", written_by: "村田椰融" },
  { title: "よつばと", written_by: "あずまきよひこ" },
  { title: "ゴールデンゴールド", written_by: "堀尾省太" },
  { title: "戦争は女の顔をしていない", written_by: "小梅けいと" },
  { title: "ＡＩの遺電子", written_by: "山田胡瓜" },
  { title: "化物語", written_by: "西尾維新" },
  { title: "とらつぐみ", written_by: "ｉｐｐａｔｕ" },
];
