import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItemText,
} from "@mui/material";
import { HelpOutline } from "@mui/icons-material";

type Hint = {
  title: string;
  hint: string;
};

const keywordSearchHints: { [key: string]: Hint[] } = {
  // キーワード検索のヒント
  keywordSearch: [
    {
      title: "タイトルと著者・作者",
      hint: "「タイトル」「著者・作者」のどちらか、または両方にキーワードを入力してください。\n両方のキーワードが設定された場合はどちらにも一致したものを返します。",
    },
    {
      title: "あいまい検索",
      hint: "キーワードはスペースで区切ることができます。「ヒーローズ　ミッション」とすると「ヒーローズ暗黒魔界ミッション」「ヒーローズビッグバンミッション」の両方にヒットします。順番もみているので「ミッションヒーローズ」はヒットしません。",
    },
    {
      title: "記号や特殊文字など",
      hint: "記号を含む「MURCIÉLAGO(ムルシエラゴ)」などは「MURCIELAGO」「MURCI?LAGO」のように、データ登録内容がブレる傾向にあります。記号を空白にして「MURCI　LAGO」で検索するとうまくいきます。\nまた、作者名「よしむら かな」で絞るのも手です。",
    },
  ],
  // マイリスト
  myList: [
    {
      title: "検索結果の範囲について",
      hint: "マイリストは過去1週間から、本日以降に発売予定の新刊を表示します。",
    },
  ],
  //設定
  preferences: [
    {
      title: "通知設定",
      hint: "検索した結果を通知する曜日を設定します。",
    },
    {
      title: "検索条件の変更",
      hint: "過去発売分を含め、検索結果を確認しながら条件を修正できます。検索対象を絞ったり、逆にあいまいにしたい場合に検索結果を確認しながら修正できます。",
    },
  ],
  // Twitter(問い合わせ)
  contact: [
    {
      title: "お問い合わせ・要望について",
      hint: "「マンガ新刊チェック！」についてご意見・ご要望・バグ指摘などありましたらTwitterにてお知らせください。",
    },
  ],
  DEFAULT: [
    {
      title: `ヒントはありません`,
      hint: "このページに関するヘルプはありません。",
    },
  ],
};

export const HelpPage: React.FC<{}> = () => {
  const location = useLocation();

  const [open, setOpen] = useState(false);
  const [hint, setHint] = useState<Hint[]>([]);
  const [title, setTitle] = useState("");
  useEffect(() => {
    if (/keyword-search/.test(location.pathname)) {
      setTitle("キーワード検索のヒント");
      setHint(keywordSearchHints.keywordSearch);
    } else if (/my-list/.test(location.pathname)) {
      setTitle("マイリストのヒント");
      setHint(keywordSearchHints.myList);
    } else if (/preferences/.test(location.pathname)) {
      setTitle("通知・検索設定のヒント");
      setHint(keywordSearchHints.preferences);
    } else if (/contact/.test(location.pathname)) {
      setTitle("コンタクト");
      setHint(keywordSearchHints.contact);
    } else {
      setTitle("ノーヒント！");
      setHint(keywordSearchHints.DEFAULT);
    }
  }, [location]);

  return (
    <>
      <IconButton color="inherit" onClick={() => setOpen(true)}>
        <HelpOutline />
      </IconButton>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {hint.map((hint, idx) => (
            <List key={idx}>
              <ListItemText primary={`💡${hint.title}`} secondary={hint.hint} />
            </List>
          ))}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setOpen(false)}>
            ＯＫ
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default HelpPage;
