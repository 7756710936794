import React from "react";
import { Alert, Link } from "@mui/material";

export const PrivacyPolicy: React.FC = () => {
  return (
    <>
      <h1>広告の配信について</h1>
      <h2>Googleの広告の配信について</h2>
      <div>
        「マンガ新刊チェック！」(以下、当サイト)は第三者配信の広告サービス「Google
        Adsense グーグルアドセンス」を利用しています。 <br />
        広告配信事業者は、ユーザーの興味に応じた広告を表示するために
        Cookie（クッキー）を使用することがあります。
        <br />
        Cookie（クッキー）を無効にする設定および Google アドセンスに関する詳細は
        「
        <Link href="https://www.google.co.jp/po1.cies/technologies/ads/">
          広告 –ポリシーと規約– Google
        </Link>
        」 をご覧ください。
      </div>
      <h2>Amazon広告の配信について</h2>
      <div>
        当サイトは、Amazon.co.jp
        を宣伝しリンクすることによってサイトが紹介料を獲得できる手段を提供することを目的に設定されたアフィリエイトプログラムである、Amazon
        アソシエイト・プログラムの参加者です。
      </div>
      <h2>Cookieの取り扱いについて</h2>
      <div>
        第三者がコンテンツおよび宣伝を提供し、訪問者から直接情報を収集し、訪問者のブラウザに
        Cookie（クッキー）を設定したりこれを認識したりする場合があります。
      </div>
      <h2>アクセス解析ツールについて</h2>
      当サイトでは、Google によるアクセス解析ツール「Google
      アナリティクス」を利用しています。
      <br />
      この Google アナリティクスはトラフィックデータの収集のために Cookie
      を使用しています。このトラフィックデータは匿名で収集されており、個人を特定するものではありません。この機能は
      Cookie
      を無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。この規約に関して、詳しくは
      <Link
        href="https://www.google.com/analytics/terms/jp.html"
        target="_blank"
        rel="noreferrer"
      >
        「 Google アナリティクス利用規約」をご覧ください。
      </Link>
      <br />
      <h2>当サイトへのコメントについて</h2>
      当サイトでは、スパム・荒らしへの対応として、コメントの際に使用された IP
      アドレスを記録しています。
      <br />
      これはブログの標準機能としてサポートされている機能で、スパム・荒らしへの対応以外にこの
      IP アドレスを使用することはありません。また、メールアドレスと URL
      の入力に関しては、任意となっております。全てのコメントは管理人が事前にその内容を確認し、承認した上での掲載となりますことをあらかじめご了承下さい。加えて、次の各号に掲げる内容を含むコメントは管理人の裁量によって承認せず、削除する事があります。
      <br />
      <ol>
        <li>特定の自然人または法人を誹謗し、中傷するもの。</li>
        <li>極度にわいせつな内容を含むもの。</li>
        <li>
          禁制品の取引に関するものや、他者を害する行為の依頼など、法律によって禁止されている物品、行為の依頼や斡旋などに関するもの。
        </li>
        <li>
          その他、公序良俗に反し、または管理人によって承認すべきでないと認められるもの。
        </li>
      </ol>
      <br />
      <h2>免責事項</h2>
      当サイトで掲載している画像の著作権・肖像権等は各権利所有者に帰属致します。権利を侵害する目的ではございません。記事の内容や掲載画像等に問題がございましたら、各権利所有者様本人が直接メールでご連絡下さい。確認後、対応させて頂きます。
      <br />
      当サイトからリンクやバナーなどによって他のサイトに移動された場合、移動先サイトで提供される情報、サービス等について一切の責任を負いません。
      <br />
      当サイトのコンテンツ・情報につきまして、可能な限り正確な情報を掲載するよう努めておりますが、誤情報が入り込んだり、情報が古くなっていることもございます。
      <br />
      当サイトに掲載された内容によって生じた損害等の一切の責任を負いかねますのでご了承ください。
      <br />
      <hr/>
      <footer>
        <Alert severity="info">プライバシーポリシー策定日 2022年3月14日</Alert>
        <hr/>
        <div className="center">&copy; 2022 マンガ新刊チェック！</div>
      </footer>
    </>
  );
};
