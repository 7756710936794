import React, { useEffect, useState } from "react";
import { Alert, Snackbar } from "@mui/material";
import { setErrorHandler } from "../settings/axios-settings";

export const GlobalAlert: React.FC<{}> = () => {
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    msg?: string;
    severity?: "warning" | "error" | "info";
  }>({ open: false });

  // スナックバーを表示するため、外部関数からコールバックされる
  const openSnackbar = (
    msg: string,
    severity: "warning" | "error" | "info" = "error"
  ) => {
    setSnackbar({ open: true, msg, severity });
  };

  const closeSnackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  useEffect(() => {
    setErrorHandler(openSnackbar);
  }, []);

  return (
    <>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={closeSnackbar}
        onClick={closeSnackbar}
      >
        <Alert severity={snackbar.severity}>{snackbar.msg}</Alert>
      </Snackbar>
    </>
  );
};
