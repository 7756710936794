import React, { ReactNode } from "react";
import { AppBar, Toolbar, IconButton, Typography, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import liff from "@line/liff";
import { Search, List, Edit, Twitter, FiberNew } from "@mui/icons-material";
import { AppMenu } from "./components/app-bar/app-menu";
import { HelpPage } from "./help";
export const Offset = styled("div")(({ theme }) => theme.mixins.toolbar);

export const AppHeaderBar: React.FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <AppBar position="fixed">
        <Toolbar>
          <AppMenu />
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, cursor: "pointer", fontWeight: "bold" }}
            onClick={() =>
              navigate(liff?.isLoggedIn() ? "/liff/keyword-search" : "/")
            }
          >
            マンガ新刊チェック！
          </Typography>
          <HelpPage />
        </Toolbar>
      </AppBar>
      <Offset />
      {children}
    </>
  );
};

export const AppFooterBar: React.FC = () => {
  const navigate = useNavigate();
  return liff?.isLoggedIn() ? (
    <AppBar position="fixed" sx={{ top: "auto", bottom: 0 }}>
      <Toolbar sx={{ display: "flex", justifyContent: "space-around" }}>
        <IconButton color="inherit" onClick={() => navigate("/liff/contact")}>
          <Twitter />
        </IconButton>
        <IconButton
          color="inherit"
          onClick={() => navigate("/liff/latest-list")}
        >
          <FiberNew />
        </IconButton>
        <IconButton
          color="inherit"
          onClick={() => navigate("/liff/keyword-search")}
        >
          <Search />
        </IconButton>
        <IconButton color="inherit" onClick={() => navigate("/liff/my-list")}>
          <List />
        </IconButton>
        <IconButton
          color="inherit"
          onClick={() => navigate("/liff/preferences")}
        >
          <Edit />
        </IconButton>
      </Toolbar>
    </AppBar>
  ) : (
    <></>
  );
};

export default AppHeaderBar;
